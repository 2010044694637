import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../components/button';
import Card from '../components/card';
import Grid from '../components/grid';
import Layout from '../components/layout';
import TwoColumn from '../components/two-column';
import { pageContext } from '../contexts/page-context';

const img1 = (
  <StaticImage
    src='../images/whoweare.png'
    alt='Who are we?'
    width={720}
    height={523}
    layout='constrained'
    placeholder='blurred'
    objectFit='contain'
  />
);

const img2 = (
  <div>
    <div className='hidden'>
      Accounting Office Bang Phli Accounting Office Bang Na Accounting Office Bang Sao Thong Accounting Office Bang Bo
      Accounting Office King Kaeo Accounting Office Samut Prakarn Accounting Office Lat Krabang Accounting Office Saimai
      Accounting Bang Phli Accounting Bang Na Accounting Bang Sao Thong Accounting Bang Bo Accounting King Kaeo
      Accounting Samut Prakarn Accounting Lat Krabang Accounting Saimai Business Registration Bang Phli Business
      Registration Bang Na Business Registration Bang Sao Thong Business Registration Bang Bo Business Registration King
      Kaeo Business Registration Samut Prakarn Business Registration Lat Krabang Business Registration Saimai Auditing
      Bang Phli Auditing Bang Na Auditing Bang Sao Thong Auditing Bang Bo Auditing King Kaeo Auditing Samut Prakarn
      Auditing Lat Krabang Auditing Saimai Registration Bang Phli Registration Bang Na Registration Bang Sao Thong
      Registration Bang Bo Registration King Kaeo Registration Samut Prakarn Registration Lat Krabang Registration
      Saimai
    </div>
    <div className='text-black text-opacity-90 flex flex-wrap justify-center items-center'>
      <h3 className='p-2'>King Kaeo</h3>
      <h3 className='p-2'>Bang Phli</h3>
      <Link to='/en/services/business-registration'>
        <h3 className='p-2 text-2xl z-10'>Business Registration</h3>
      </Link>
      <h3 className='p-2'>Bang Bo</h3>
      <h3 className='p-2'>Lat Krabang</h3>
      <Link to='/en/contact-us'>
        <h3 className='p-2 text-2xl z-10'>Accounting Office</h3>
      </Link>
      <h3 className='p-2'>Bang Sao Thong</h3>
      <Link to='/en/services/accounting'>
        <h3 className='p-2 text-2xl z-10'>Accounting</h3>
      </Link>
      <h3 className='p-2'>Bang Na</h3>
      <h3 className='p-2'>Saimai</h3>
      <h3 className='p-2'>Samut Prakarn</h3>
      <Link to='/en/services/auditing'>
        <h3 className='p-2 text-2xl z-10'>Auditing</h3>
      </Link>
      <Link to='/en/services/business-registration'>
        <h3 className='p-2 text-2xl z-10'>Registration</h3>
      </Link>
    </div>
    <StaticImage
      src='../images/problems.png'
      alt='Lack of know-how'
      width={720}
      height={480}
      layout='constrained'
      placeholder='blurred'
      objectFit='contain'
    />
  </div>
);

const problems = [
  "Want to start a business but don't know how to begin.",
  'Currently accounting is not conformance with laws, there may be penalty.',
  'Lack of accountant.',
  'Currently accounting cannot be used for business planning.',
  'Lack of tax planning, making taxing improper and inappropriate.',
  'Found problems in contacting government departments.',
  'Spending time on accounting, there is no time left to manage business.',
  'Hiring a full-time accountant is so expensive.',
];

export default function IndexPage() {
  return (
    <pageContext.Provider value={{ lang: 'en' }}>
      <Layout
        page='home'
        title='Accounting, Financial Statements, and Tax Planning'
        description='AF Kanbunchee are a team of professional accountants who is responsible for keeping and interpreting financial records. We are responsible for finance-related tasks.'
        url='/en'>
        <TwoColumn img={img1}>
          <h1 className='text-black text-opacity-90 leading-normal'>Who are we?</h1>
          <div className='mt-2 mb-6 text-black text-opacity-70'>
            We are a team of professional accountants who is responsible for keeping and interpreting financial records.
            We, accountants, are responsible for a wide range of finance-related tasks. We support all business areas,
            including transport, construction, retail, gold shop, pharmaceutical shop, or either individual clients or
            larger businesses and organizations employing us.
          </div>
          <Button to='/en/about-us' text='Find Out More' wrapped />
        </TwoColumn>
        <Grid header='Promotion' cols={3} link='/en/services' linkName='All Our Services'>
          <Card image='../images/registration.png' header='Business Registration'>
            12,000 THB
            <br />
            First Month Free
            <br />
            Discount 20% on Second Month
          </Card>
          <Card image='../images/accounting.png' header='Accounting'>
            2,500 THB/Month
            <br />
            First Month Free
            <br />
            Discount 10% on Second Month
          </Card>
          <Card image='../images/audit.png' header='Auditing'>
            5,000 THB
            <br />
            No Service for Empty Budget
          </Card>
        </Grid>
        <TwoColumn img={img2}>
          <h1 className='text-black text-opacity-90 leading-normal mb-4'>It's not a problem anymore</h1>
          <div className='mt-2 mb-6 text-black text-opacity-70 flex flex-col'>
            {problems.map((p) => (
              <div className='border-l-green-600 border-l-4 shadow-md p-4 w-full mb-4'>{p}</div>
            ))}
          </div>
        </TwoColumn>
      </Layout>
    </pageContext.Provider>
  );
}
